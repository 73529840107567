<template>
  <TileFrame :size="size" @click="handleTileClick">
    <template #image>
      <ImageBasic
        :image-data="tileData.image"
        class="object-cover w-full h-full aspect-video"
      ></ImageBasic>
    </template>

    <div class="flex flex-col h-full p-md">
      <div>
        <h5 ref="overline" class="text-sm font-light">
          {{ tileData.overline }}
        </h5>
        <h4 class="break-words pt-sm">
          {{ tileData.title }}
        </h4>
      </div>
      <div class="h-full pt-sm">
        <p>
          {{ tileData.description }}
        </p>
      </div>
      <div class="flex justify-end self-end pt-md row-end-[none]">
        <LinkBasic
          :label="t('basicLink.more')"
          :link="`/${tileData.uri}`"
          :filter-params="queryArgs"
          target="_self"
          icon="fas fa-chevron-right"
          class="text-primary-base"
        />
      </div>
    </div>
  </TileFrame>
</template>
<script lang="ts"></script>
<script setup lang="ts">
import type { LocationQueryRaw } from 'vue-router';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import { ETileSize } from '@/@types/tile-size';
import TileFrame from '@/components/tiles/components/tileFrame/TileFrame.vue';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import type { PostTileData } from './tileType';

const { t } = useTrans();

const overline = ref<HTMLDivElement>(null);

defineExpose({ overline });

const props = defineProps({
  tileData: {
    type: Object as PropType<PostTileData>,
    required: true,
  },
  size: {
    type: String as PropType<ETileSize>,
    required: false,
    default: ETileSize.NORMAL,
  },
  queryArgs: {
    type: Object as PropType<LocationQueryRaw>,
    required: false,
    default: null,
  },
});

function handleTileClick() {
  navigateTo({
    path: '/' + props.tileData.uri,
    query: props.queryArgs,
  });
}
</script>
<style scoped lang="postcss"></style>
